import type { Calculate } from '~/type/Installment'

export function CalculateSerialize(el: { [key: string]: any }): Calculate {
  return {
    grade: el.grades?.[0]?.calculated,
    initialPercent: {
      min: el.min_max_first_fee[0],
      max: el.min_max_first_fee[1],
    },
    period: {
      min: el.min_max_period[0],
      max: el.min_max_period[1],
    },
    price: {
      min: el.min_max_price[0],
      max: el.min_max_price[1],
    },
  }
}
