import type { Events } from '~/plugins/emitter'

export default function rassrochkaPage(type: Events['rassrochka-page']['type']) {
  switch (type) {
    case 'price':
      return {
        event: 'web_bnpl_calc_price',
      }
    case 'firstfee':
      return {
        event: 'web_bnpl_calc_firstfee',
      }
    case 'period':
      return {
        event: 'web_bnpl_calc_period',
      }
    case 'share':
      return {
        event: 'web_bnpl_calc_share',
      }
    case 'qrshare':
      return {
        event: 'web_bnpl_calc_qrshare',
      }
  }
}
