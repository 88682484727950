import { createPopper } from '@popperjs/core'
import hoverintent from 'hoverintent'
import type { Ref } from '@vue/composition-api'
import { ref } from '@vue/composition-api'

export default function bonusPopper(
  { handler, content }: { handler: Ref<HTMLElement | undefined>; content: Ref<HTMLElement | undefined> },
) {
  let instance: ReturnType<typeof createPopper> | null
  let $hoverintent: ReturnType<typeof hoverintent> | null
  let timer: number
  const isActive = ref(false)

  function init() {
    if (!instance && handler.value && content.value) {
      instance = createPopper(handler.value, content.value, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 17],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 10,
            },
          },
        ],
      })
    }
  }

  function enable() {
    init()
    isActive.value = true
  }

  function create() {
    if (handler.value)
      $hoverintent = hoverintent(handler.value, enable, () => { })
  }

  function destroy() {
    if (instance) {
      instance.destroy()
      instance = null
    }
  }

  function disable() {
    destroy()
    isActive.value = false
    timer = 0
  }

  function onMouseEnter() {
    if (!isActive.value)
      isActive.value = true

    if (typeof timer === 'number') {
      window.clearTimeout(timer)
      timer = 0
    }
  }

  function onMouseLeave() {
    if (isActive.value && !timer) {
      timer = window.setTimeout(() => {
        disable()
      }, 500)
    }
  }

  function removeInstance() {
    if ($hoverintent)
      $hoverintent.remove()
  }

  return {
    init, destroy, disable, onMouseEnter, onMouseLeave, create, removeInstance, isActive,
  }
}
