import type { Context } from '@nuxt/types'
import { stringify } from 'qs'
import type { useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { ProductSerialize } from '~/serializer/product/Product'
import type { Product } from '~/type/product/Product'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import { FaqSerializer } from '~/serializer/Faq'
import { useTokenStore } from '~/store/token'
import { CalculateSerialize } from '~/serializer/pages/Installment'

export default {
  async calculate(this: Context, { price, period, first_fee_in_rouble }: { price: number; period: number; first_fee_in_rouble: number }) {
    const { $axios } = this
    const { accessToken } = storeToRefs(useTokenStore())
    const result = await $axios.$get(
      `${process.env.RASSROCHKA_URL}/api/tariffs/2?${stringify({
        price,
        period,
        first_fee_in_rouble,
      })}`, {
        headers: {
          Authorization: `Bearer ${accessToken.value?.value}`,
        },
      })

    return {
      result: CalculateSerialize(result),
    }
  },

  async products(this: Context, { providerCode, size, page }: { providerCode: string; size: number; page: 1 }) {
    const { $axios } = this
    const { result } = await $axios.$get<APIRequestSuccess<{ ITEMS: any[] }>>(
      `/api/v1/installment/products?${stringify({
        provider_code: providerCode,
        size,
        page,
      })}`,
    )
    return result.map((item: Product) => ProductSerialize(item))
  },

  async sendInstallment(this: Context | ReturnType<typeof useContext>,
    { phone, productId, comment }: { phone: string; productId: number; comment: string },
  ) {
    const { $axios } = this

    const response = (
      await $axios.$post<APIRequestSuccess>(
        'api/v1/installment/requests',
        stringify({
          provider_code: 'Installment05ru',
          phone,
          product_id: productId,
          comment: `Второй номер телефона клиента: ${comment} `,
        }),
      )
    )?.result

    return response
  },
  async getApplicationData(this: Context | ReturnType<typeof useContext>, { orderId }: { orderId: number }) {
    const { $axios } = this

    const { result } = (await $axios.get<{ result: { clientPhone: string } }>(
      `api/v1/service/poscredit/bid-data?${stringify({
        bid_id: orderId,
      })}`,
    )).data

    return {
      result,
    }
  },
  async getQuestions(this: Context) {
    const { $axios } = this

    const response = (await $axios.$get<{ result: any[] }>('/api/v1/installment/faq'))

    return {
      result: response.result.length ? response.result.map(el => FaqSerializer(el)) : [],
    }
  },
}
