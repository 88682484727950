import { defineNuxtPlugin } from '@nuxtjs/composition-api'

interface Varioqub {
  i?: string
  experiments: string
  flags: {
    n: string
    v: string | boolean | number
    t: string
  }[]
  testids?: number[]
}

// Время (час), через которое будет делаться повторный запрос, после последнего неудачного запроса
const checkInterval = 360000
let lastCheckTime: number | null
let varioqubPromise: Promise<Varioqub> | null = null

function serializeData(data: Varioqub) {
  return {
    userId: data.i,
    experiments: data.experiments,
    testids: data.testids,
    features: data.flags.reduce((acc, flag) => {
      acc[flag.n] = {
        value: flag.v,
        type: flag.t,
      }
      return acc
    }, {} as Record<string, { value: string | number | boolean; type: string }>),
  }
}

export default defineNuxtPlugin(async (ctx, inject) => {
  if (ctx.$config.varioqubUrl) {
    const clientId = `metrika.${ctx.env.yandexCounter}`
    const url = ctx.env.baseURL
    const i = ctx.$cookies.get('_ymab_param')

    const timeToCheck = lastCheckTime ? Date.now() - lastCheckTime >= checkInterval : true
    let data: any | null = null

    /**
     * Варианты тестов грузим на сервере и передаем на клиент через ssr контекст,
     * чтобы не грузить варианты два раза и на сервере и на клиенте
     */
    if (process.server) {
      const nodeCache = (ctx.ssrContext as SSRContext)?.nodeCache

      if (nodeCache) {
        const remain = nodeCache.getTtl('varioqubFeatures') || 0

        if (nodeCache.has('varioqubFeatures')) {
          if (remain < (60 * 2) && timeToCheck) {
            /**
             * Загрузка вариантов реализована так, что она не блочит загрузку
             * страниц проекта, потому что нет ожидания этого запроса. В итоге когда запрос завершится
             * он просто обновит значение вариантов в кеше, чтобы уже последующие клиенты получили
             * более свежие варианты тестов
             */
            varioqubPromise = varioqubPromise || ctx.$axios.$get(ctx.$config.varioqubUrl, {
              params: {
                client_id: clientId,
                url,
                i,
              },
            })
            varioqubPromise.then((response) => {
              nodeCache.set('varioqubFeatures', serializeData(response), 60 * 10)
              lastCheckTime = null
            }).catch((e) => {
              lastCheckTime = Date.now()
              new ctx.$baseError({
                message: 'Не удалось получить варианты АБ тестов',
                native: e,
              })
            })
          }

          data = nodeCache.get('varioqubFeatures')!
        }
        else {
          try {
            if (timeToCheck) {
              varioqubPromise = varioqubPromise || ctx.$axios.$get(ctx.$config.varioqubUrl, {
                params: {
                  client_id: clientId,
                  url,
                  i,
                },
              })
              data = await varioqubPromise
              nodeCache.set('varioqubFeatures', serializeData(data), 60 * 10)
              lastCheckTime = null
            }
          }
          catch (e) {
            lastCheckTime = Date.now()
            new ctx.$baseError({
              message: 'Не удалось получить варианты АБ тестов',
              native: e,
            })
          }
        }
      }
      else {
        try {
          if (timeToCheck) {
            varioqubPromise = varioqubPromise || ctx.$axios.$get(ctx.$config.varioqubUrl, {
              params: {
                client_id: clientId,
                url,
                i,
              },
            })
            const response = await varioqubPromise
            data = serializeData(response)
            lastCheckTime = null
          }
        }
        catch (e) {
          lastCheckTime = Date.now()
          new ctx.$baseError({
            message: 'Не удалось получить варианты АБ тестов',
            native: e,
          })
        }
      }

      ctx.beforeNuxtRender(({ nuxtState }) => {
        nuxtState.varioqubData = data
      })
    }
    else {
      data = ctx.nuxtState.varioqubData
    }

    const vq = {
      ...data,
      getFeatureValue(name: string, defaultValue?: string) {
        if (this.features?.[name]?.value)
          return this.features[name]?.value

        return defaultValue
      },
    }

    inject('vq', vq)

    // Очищаем промис после использования данных для настройки Varioqub
    varioqubPromise = null
  }
  else {
    console.log('Varioqub: нет переменных окружения для инициализации')
  }
})
