var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "category-menu"
  }, [_vm.error ? _c('div', {
    staticClass: "category-menu__error"
  }, [_c('h5', {
    staticClass: "h5"
  }, [_vm._v("\n      При получении списка категорий произошла ошибка. Пожалуйста, попробуйте позже\n    ")])]) : _c('div', {
    staticClass: "category-menu__wrapper"
  }, [_c('div', {
    staticClass: "category-menu__header"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBack,
      expression: "showBack"
    }],
    staticClass: "category-menu__back",
    on: {
      "click": _vm.backToPrevious
    }
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showBack,
      expression: "!showBack"
    }],
    staticClass: "category-menu__close",
    on: {
      "click": _vm.close
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "category-menu__header-title-wrap"
  }, [_c('nuxt-link', {
    staticClass: "category-menu__header-title h5",
    style: _vm.borderBottom,
    attrs: {
      "to": _vm.mainMenuLink,
      "title": _vm.mainMenuTitle
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMobile.apply(null, arguments);
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.mainMenuTitle) + "\n        ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "category-menu__search",
    on: {
      "click": function ($event) {
        return _vm.goToSearch();
      }
    }
  })]), _vm._v(" "), _c('div', {
    ref: "categoryMenuListWrapper",
    staticClass: "category-menu__list-wrapper"
  }, [_c('div', {
    ref: "categoryMenuList",
    staticClass: "category-menu__list",
    class: [{
      'category-menu__list_current': _vm.activeCurrent
    }],
    style: _vm.shiftTransform
  }, _vm._l(_vm.list, function (category, i) {
    var _category$children, _category$thumbnail, _category$thumbnail2, _category$thumbnail3;

    return _c('div', {
      key: category.id,
      staticClass: "category-menu__el",
      class: [{
        'category-menu__el_nested': (_category$children = category.children) === null || _category$children === void 0 ? void 0 : _category$children.length,
        'category-menu__el_active': _vm.activeIndex === i && _vm.isDesktop || category.isActive
      }]
    }, [_c('a', {
      staticClass: "category-menu__el-wrapper",
      attrs: {
        "href": category.path,
        "data-e2e": "categoryMenuItem",
        "title": category.name
      },
      on: {
        "mouseover": function ($event) {
          _vm.isDesktop && _vm.changeActiveIndex(i);
        },
        "click": function ($event) {
          $event.preventDefault();
          _vm.isDesktop ? _vm.onNavigatePage(category.path) : _vm.enableOnMobile(category, true);
        }
      }
    }, [_c('div', {
      staticClass: "category-menu__img-wrapper"
    }, [_c('img', {
      staticClass: "category-menu__img",
      attrs: {
        "src": (_category$thumbnail = category.thumbnail) === null || _category$thumbnail === void 0 ? void 0 : _category$thumbnail.x1,
        "alt": category.name,
        "srcset": ((_category$thumbnail2 = category.thumbnail) === null || _category$thumbnail2 === void 0 ? void 0 : _category$thumbnail2.x2) && (((_category$thumbnail3 = category.thumbnail) === null || _category$thumbnail3 === void 0 ? void 0 : _category$thumbnail3.x2) + " 2x")
      }
    })]), _vm._v(" "), _c('span', {
      staticClass: "category-menu__text"
    }, [_vm._v(_vm._s(category.name))])]), _vm._v(" "), _c('div', {
      staticClass: "category-menu__list",
      class: [{
        'category-menu__list_current': _vm.isMobile && category.activeCurrent,
        'category-menu__list_brands': category.brands && category.brands.length
      }]
    }, [_vm._l(category.children, function (child) {
      var _child$children, _child$thumbnail, _child$thumbnail2, _child$children3, _child$children4;

      return _c('div', {
        key: child.id,
        staticClass: "category-menu__el",
        class: [{
          'category-menu__el_nested': (_child$children = child.children) === null || _child$children === void 0 ? void 0 : _child$children.length,
          'category-menu__el_active': child.isActive,
          'category-menu__el_brand': category.brands && category.brands.length
        }]
      }, [_c('a', {
        staticClass: "category-menu__el-wrapper",
        attrs: {
          "href": child.path,
          "data-e2e": "categoryMenuItem",
          "title": child.name
        },
        on: {
          "click": function ($event) {
            $event.preventDefault();
            _vm.isDesktop ? _vm.onNavigatePage(child.path) : _vm.enableOnMobile(child, true);
          }
        }
      }, [_c('div', {
        staticClass: "category-menu__img-wrapper"
      }, [_vm.activeCategoriesIndex.includes(i) && _vm.isDesktop && _vm.menuState || category.isActiveImage ? _c('img', {
        staticClass: "category-menu__img",
        attrs: {
          "src": (_child$thumbnail = child.thumbnail) === null || _child$thumbnail === void 0 ? void 0 : _child$thumbnail.x1,
          "alt": child.name,
          "srcset": ((_child$thumbnail2 = child.thumbnail) === null || _child$thumbnail2 === void 0 ? void 0 : _child$thumbnail2.x2) && ((child.thumbnail.x2) + " 2x")
        }
      }) : _vm._e()]), _vm._v(" "), _c('span', {
        staticClass: "category-menu__text"
      }, [_vm._v(_vm._s(child.name))])]), _vm._v(" "), _c(_vm.isProductPage ? 'client-only' : 'div', {
        tag: "component"
      }, [_c('div', {
        staticClass: "category-menu__list",
        class: [{
          'category-menu__list_current': _vm.isMobile && child.activeCurrent
        }]
      }, [_vm._l(child.children, function (childLast, index) {
        var _childLast$children, _child$children2, _childLast$thumbnail, _childLast$thumbnail2, _childLast$thumbnail3;

        return _c('div', {
          key: childLast.id,
          staticClass: "category-menu__el",
          class: [{
            'category-menu__el_nested': (_childLast$children = childLast.children) === null || _childLast$children === void 0 ? void 0 : _childLast$children.length,
            'category-menu__el_active': childLast.isActive,
            'category-menu__el_hidden': index >= 10 && ((_child$children2 = child.children) === null || _child$children2 === void 0 ? void 0 : _child$children2.length) !== 10 && !_vm.activeMoreCategories.includes(child.id)
          }]
        }, [_c('a', {
          staticClass: "category-menu__el-wrapper",
          attrs: {
            "href": childLast.path,
            "data-e2e": "categoryMenuItem",
            "title": childLast.name
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              _vm.isDesktop ? _vm.onNavigatePage(childLast.path) : _vm.enableOnMobile(childLast);
            }
          }
        }, [_c('div', {
          staticClass: "category-menu__img-wrapper"
        }, [_vm.activeCategoriesIndex.includes(i) && _vm.isDesktop && _vm.menuState || child.isActiveImage ? _c('img', {
          staticClass: "category-menu__img",
          attrs: {
            "src": (_childLast$thumbnail = childLast.thumbnail) === null || _childLast$thumbnail === void 0 ? void 0 : _childLast$thumbnail.x1,
            "alt": childLast.name,
            "srcset": ((_childLast$thumbnail2 = childLast.thumbnail) === null || _childLast$thumbnail2 === void 0 ? void 0 : _childLast$thumbnail2.x2) && (((_childLast$thumbnail3 = childLast.thumbnail) === null || _childLast$thumbnail3 === void 0 ? void 0 : _childLast$thumbnail3.x2) + " 2x")
          }
        }) : _vm._e()]), _vm._v(" "), _c('span', {
          staticClass: "category-menu__text"
        }, [_vm._v(_vm._s(childLast.name))])])]);
      }), _vm._v(" "), child !== null && child !== void 0 && (_child$children3 = child.children) !== null && _child$children3 !== void 0 && _child$children3.length ? _c('span', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: (child === null || child === void 0 ? void 0 : (_child$children4 = child.children) === null || _child$children4 === void 0 ? void 0 : _child$children4.length) >= 11 && !_vm.activeMoreCategories.includes(child.id),
          expression: "child?.children?.length >= 11 && !activeMoreCategories.includes(child.id)"
        }],
        staticClass: "category-menu__el-link",
        on: {
          "click": function ($event) {
            return _vm.onClickShowMoreCategories(child.id);
          }
        }
      }, [_vm._v("\n                    Показать всё\n                  ")]) : _vm._e()], 2)])], 1);
    }), _vm._v(" "), category.brands.length ? _c('div', {
      staticClass: "category-menu__brands"
    }, _vm._l(category.brands, function (item) {
      var _item$thumbnail, _item$thumbnail2;

      return _c('nuxt-link', {
        key: item.id,
        staticClass: "category-menu__brand",
        attrs: {
          "to": ("/brands/" + (item.code || item.id) + "/")
        },
        nativeOn: {
          "click": function ($event) {
            return _vm.hideMobile.apply(null, arguments);
          }
        }
      }, [_c('img', {
        staticClass: "category-menu__brand-img",
        attrs: {
          "src": item.image,
          "alt": item.name,
          "srcset": (((_item$thumbnail = item.thumbnail) === null || _item$thumbnail === void 0 ? void 0 : _item$thumbnail.x1) + " 1x, " + ((_item$thumbnail2 = item.thumbnail) === null || _item$thumbnail2 === void 0 ? void 0 : _item$thumbnail2.x2) + " 2x")
        }
      })]);
    }), 1) : _vm._e()], 2)]);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }