import type { UserActivity, UserActivityStatus } from '~/type/UserActivity'

/**
 * Статусы карточек активностей
  * hidden - если срок ACTIVE_TO больше текущей даты, задача больше не актуальна
  * unavailable - если ACTIVE_FROM меньше текущей даты
  * avaiable - если текущая дата между ACTIVE_FROM и ACTIVE_TO
  * completed - не вадано вознаграждение, но задача сделана
  * archived - если награда выдана
  */

function calculateActivityStatus({
  from,
  to,
  isPassed,
  isRewarded,
}: {
  from: string
  to: string
  isPassed: boolean
  isRewarded: boolean
}): UserActivityStatus {
  // Проверяем была ли выдана награда
  if (isRewarded)
    return 'archived'

  // Проверяем было ли выполнено задание
  if (isPassed)
    return 'completed'

  // Если задание протухло по времени
  const currentDate = new Date()

  if (to && from) {
    const activeToDate = new Date(to)
    const activeFromDate = new Date(from)

    if (currentDate > activeToDate)
      return 'hidden'

    if (currentDate < activeFromDate)
      return 'unavailable'
  }

  // И если ничего из этого, оно доступно для выполнения
  return 'available'
}

export function UserActivitySerializer(el: { [key: string]: any }): UserActivity {
  const status = calculateActivityStatus({
    from: el.ACTIVE_FROM,
    to: el.ACTIVE_TO,
    isPassed: el.IS_PASSED,
    isRewarded: el.IS_REWARDED,
  })

  return {
    id: el.ID,
    code: el.CODE,
    status,
    title: el.NAME,
    description: el.DESCRIPTION,
    link: el.LINK,
    image: el.IMAGE,
    checkOnClient: el.CHECK_ON_CLIENT,
    time: {
      from: el.ACTIVE_FROM,
      to: el.ACTIVE_TO,
    },
    reward: {
      bonus: el.REWARDS?.BONUS?.AMOUNT,
      point: el.REWARDS?.ACTIVITY_POINTS?.AMOUNT,
      promocode: el.REWARDS?.PROMOCODE && {
        value: el.REWARDS.PROMOCODE.PROMOCODE_VALUE,
        description: el.REWARDS.PROMOCODE.PROMOCODE_DESCRIPTION,
      },
    },
  }
}
