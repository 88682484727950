var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "input-code",
    class: [{
      'input-code_error': _vm.error
    }, {
      'input-code_loading': _vm.loading
    }, {
      'input-code_success': _vm.success
    }]
  }, _vm._l(_vm.count, function (_, index) {
    return _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.data[index],
        expression: "data[index]"
      }],
      key: index,
      ref: "root",
      refInFor: true,
      staticClass: "input-code__input",
      attrs: {
        "type": "number",
        "inputmode": "numeric",
        "autocomplete": "one-time-code",
        "disabled": _vm.disabled || index > 0,
        "maxlength": "1"
      },
      domProps: {
        "value": _vm.data[index]
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.data, index, $event.target.value);
        }, function ($event) {
          return _vm.handleInput(index, $event);
        }],
        "keypress": function ($event) {
          return _vm.handleKeyPress($event);
        },
        "keydown": function ($event) {
          return _vm.handleKeyDown(index, $event);
        },
        "paste": _vm.handlePaste
      }
    });
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }