
import { ref } from '@vue/composition-api';
interface Props {
  /**
  * Текст который нужно скопировать
  */
  copy: string;
  fluid?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  copy: {
    key: "copy",
    required: true,
    type: String
  },
  fluid: {
    key: "fluid",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isCopied = ref(false);
  function onClick() {
    if (!isCopied.value) {
      const $temp = document.createElement('input');
      $temp.value = props.copy;
      document.body.appendChild($temp);
      $temp.select();
      document.execCommand('copy');
      $temp.remove();
      isCopied.value = true;
      emit('click');
    }
  }
  return {
    isCopied,
    onClick
  };
};
export default __sfc_main;
