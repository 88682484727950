import type { Context } from '@nuxt/types'
import { TaskSerialize } from '~/serializer/pages/Promo'
import { UserActivitySerializer } from '~/serializer/pages/UserActivitySerializer'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import type { UserActivityStatus } from '~/type/UserActivity'

export default {
  async getListActivities(this: Context) {
    const { $axios } = this
    const context = this

    const get = await Promise.all([
      (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any } }>('/api/v1/tasks'))?.result,
      (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any } }>('/api/v1/games'))?.result,
    ])

    const tasksList = Array.isArray(get[0].ITEMS) ? get[0].ITEMS?.map(item => TaskSerialize(context, item)).sort((a, b) => a.daysToUnlock! - b.daysToUnlock!) : []
    const gamesList = Array.isArray(get[1].ITEMS) ? get[1].ITEMS?.map(item => TaskSerialize(context, item)).filter(item => (item.isActiveWordle && item.isAvailable) || (!item.isPassed && item.title !== 'Wordle')).sort((a, b) => a.daysToUnlock! - b.daysToUnlock!) : []

    const result = gamesList.concat(tasksList)
    return {
      result: {
        tasks: result,
      },
    }
  },

  async checkTask(this: Context, { taskId }: { taskId: number }) {
    const { $axios } = this
    const result = (await $axios.$post<APIRequestSuccess>(`/api/v1/tasks/${taskId}/check`))

    return {
      result: {
        success: result,
      },
    }
  },

  async getWordleGame(this: Context) {
    const { $axios } = this
    const context = this

    const get = (await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any } }>('/api/v1/games'))?.result
    const wordle = Array.isArray(get.ITEMS) ? get.ITEMS?.map(item => TaskSerialize(context, item)).filter(item => item.title?.includes('Wordle') && item.isAvailable) : []

    return {
      result: {
        wordle,
      },
    }
  },

  async accrueTask(this: Context, { taskId }: { taskId: number }) {
    const { $axios } = this
    const result = (await $axios.$post<APIRequestSuccess>(`/api/v1/tasks/${taskId}/accrue`))

    return {
      result: {
        success: result,
      },
    }
  },

  async getUserTasks(this: Context) {
    const { $axios } = this
    const { result } = await $axios.$get<{ result: { ITEMS: any[]; PAGINATION: any } }>('/api/v1/tasks')

    // Порядок отображения статусов
    const sortTypes: UserActivityStatus[] = ['completed', 'available', 'unavailable', 'archived']

    const tasksList = Array.isArray(result.ITEMS)
      ? result.ITEMS.map(item => UserActivitySerializer(item)).filter(task => task.status !== 'hidden')
        .sort((firstTask, secondTask) => {
          const firstTaskPosition = sortTypes.indexOf(firstTask.status)
          const secondTaskPosition = sortTypes.indexOf(secondTask.status)
          return firstTaskPosition - secondTaskPosition
        })
      : []

    return {
      result: {
        tasks: tasksList,
      },
    }
  },

  async receiveUserTasks(this: Context, { id }: { id: number }) {
    const { $axios } = this
    const response = await $axios.$post(`/api/v1/tasks/${id}/receive`)
    return response
  },

  async confirmUserTasks(this: Context, { id }: { id: number }) {
    const { $axios } = this
    const response = await $axios.$post(`/api/v1/tasks/${id}/confirm`)
    return response
  },
}
