
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Button, Checkbox, Form, FormElement } from '05-ui-kit';
import { computed, inject, nextTick, onMounted, ref, shallowRef, watch } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';
import type { Context } from '@nuxt/types';
import Tooltip from '05-ui-kit/lib/Tooltip';
import { useAuthStore } from '~/store/modal/auth';
import Auth from '~/helper/Auth';
import type { EmitteryType } from '~/plugins/emitter';
import type { StatusType } from '~/type/StatusType';
import InputPhone from '~/components/InputPhone/InputPhone.vue';
import InputCode from '~/components/InputCode/InputCode.vue';
import Timer from '~/components/Timer/Timer.vue';
import { create, getToken, handle, init } from '~/helper/yandexCaptcha';
import { useTokenStore } from '~/store/token';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emitter = inject<EmitteryType>('emitter')!;
  const code = ref('');
  const phone = ref('');
  let getRequest: (params: {
    phone: string;
    short: boolean;
    recaptchaToken?: string;
  }) => void;
  const activeTypeBlock = ref<'code' | 'phone'>('phone');
  const isTermsConfirm = ref(true);
  const tokenStore = useTokenStore();
  const inputPhoneRef = shallowRef<InstanceType<typeof InputPhone>>();
  const inputCodeRef = shallowRef<InstanceType<typeof InputCode>>();
  const phoneObserver = shallowRef<InstanceType<typeof ValidationObserver>>();
  const accessToken = computed(() => tokenStore.accessToken?.value);
  const authStore = useAuthStore();
  const {
    $config,
    $gb,
    $api
  } = useContext();
  const isYandexCaptchaEnabled = ref($gb?.getFeatureValue('yandex-captcha', false));
  watch(() => authStore.active, isModalShow => {
    if (isModalShow) setFocus(activeTypeBlock.value);
  });
  watch(() => activeTypeBlock.value, type => setFocus(type));
  onMounted(() => {
    setFocus(activeTypeBlock.value);
    if (isYandexCaptchaEnabled.value) init();
  });
  function close(state: boolean) {
    authStore.disabled(state);
  }
  function reset() {
    activeTypeBlock.value = activeTypeBlock.value === 'code' ? 'phone' : activeTypeBlock.value;
    code.value = '';
    phone.value = '';
  }
  function setFocus(type: 'code' | 'phone') {
    if (type === 'phone') nextTick(() => isInput(inputPhoneRef.value?.$refs.root) && inputPhoneRef.value?.$refs.root.focus());else if (type === 'code' && code.value.length) nextTick(() => isInputArray(inputCodeRef.value?.$refs.root) && inputCodeRef.value?.$refs.root[code.value.length].focus());else nextTick(() => isInputArray(inputCodeRef.value?.$refs.root) && inputCodeRef.value?.$refs.root[0].focus());
    function isInputArray(list: any): list is HTMLInputElement[] {
      if (!Array.isArray(list)) throw new Error('InputCode root is not an array');
      list.forEach(isInput);
      return true;
    }
    function isInput(el: any): el is HTMLInputElement {
      if (el && !(el instanceof HTMLInputElement)) throw new Error('InputCode root array is not contains html inputs');
      return true;
    }
  }
  function getTimeSendConfirmCode(seconds: number) {
    return seconds < 10 ? `0:0${seconds}` : `0:${seconds}`;
  }
  function onSubmitInputCode(value: string, check: (params: {
    code: string;
    type: string;
    accessToken: string;
  }) => Promise<void>) {
    code.value = value;
    if (code.value.length === 4 && accessToken.value) check({
      code: code.value,
      type: 'sms',
      accessToken: accessToken.value
    });
  }
  function onSuccess(this: Context, {
    response,
    changeStatus
  }: {
    response: Awaited<ReturnType<typeof $api.auth.checkCodeAndLogin>>;
    changeStatus: (status: StatusType) => void;
  }) {
    Auth({
      contextData: this,
      response: response.result,
      request: $api.user.sync
    });
    emitter.emit('auth-passed', {
      phone: phone.value,
      activeTypeBlock: activeTypeBlock.value
    });
    /**
    * закрываем окно через небольшой тайминг после очистки данных и сброса состояния UIActionEntity, чтобы пользователь мог понять что произошло и что авторизация прошла успешно.
    */
    window.setTimeout(() => {
      reset();
      changeStatus('initial');
      close(true);
    }, 300);
  }
  function onErrorSendConfirmCode({
    changeStatus
  }: {
    changeStatus: (status: StatusType) => void;
  }) {
    const resetWatcher = watch(phone, () => {
      changeStatus('initial');
      resetWatcher();
    });
  }
  function onCreateCaptcha() {
    create({
      options: {
        sitekey: $config.captchaKey
      },
      onSuccess(token) {
        getRequest({
          phone: phone.value,
          short: true,
          recaptchaToken: token
        });
      },
      onError() {
        Tooltip({
          title: 'Не удалось авторизоваться',
          description: 'Возникла критическая ошибка JS, пожалуйста, свяжитесь с поддержкой!'
        });
      }
    });
  }
  window.onCreateCaptcha = onCreateCaptcha;
  function onSubmitInputPhone(request: (params: {
    phone: string;
    short: boolean;
    recaptchaToken?: string;
  }) => Promise<void>) {
    if ($config.deployEnvironment === 'production' && isYandexCaptchaEnabled.value) {
      getRequest = request;
      const token = getToken();

      /**
      * Создание капчи вынесено в отдельную функцию и стоит условие на тип активного блок, потому что:
      * Для подтверждения телефона требуется валидация, поэтому капча проверяется/создается
      * при успешном завершении валидации.
      * Для проверки кода валидация не требуется, поэтому запрос отправляется сразу.
      */
      if (activeTypeBlock.value === 'phone') {
        phoneObserver.value?.handleSubmit(() => {
          handle({
            token,
            sitekey: $config.captchaKey,
            callback: (token: string) => request({
              phone: phone.value,
              short: true,
              recaptchaToken: token
            })
          });
        });
      } else {
        handle({
          token,
          sitekey: $config.captchaKey,
          callback: (token: string) => request({
            phone: phone.value,
            short: true,
            recaptchaToken: token
          })
        });
      }
    } else {
      if (activeTypeBlock.value === 'phone') {
        phoneObserver.value?.handleSubmit(() => request({
          phone: phone.value,
          short: true
        }));
      } else {
        request({
          phone: phone.value,
          short: true
        });
      }
    }
  }
  function onOverlayClick(e: Event) {
    if (e.target instanceof HTMLDivElement) {
      const isOverlay = e.target.classList.contains('auth-modal-overlay');
      if (isOverlay) close(false);
    }
  }
  return {
    code,
    phone,
    activeTypeBlock,
    isTermsConfirm,
    inputPhoneRef,
    inputCodeRef,
    phoneObserver,
    authStore,
    $api,
    close,
    getTimeSendConfirmCode,
    onSubmitInputCode,
    onSuccess,
    onErrorSendConfirmCode,
    onSubmitInputPhone,
    onOverlayClick
  };
};
__sfc_main.components = Object.assign({
  Button,
  FormElement,
  InputCode,
  Checkbox,
  Timer,
  ValidationObserver,
  Form,
  ValidationProvider,
  InputPhone
}, __sfc_main.components);
export default __sfc_main;
